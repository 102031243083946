import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';


const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2">Our P2P Crypto Exchange Platform <span className="bluecolor">Development Approach</span></h2>
            <p className="pharagraph head">We at Coinsclone follow the below-mentioned P2P crypto exchange development process for the clientele project.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center" >
          <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/binance/planning-and-requirment-gathering.png"
                alt="project analysis"
                width={310}
                height={297}
              />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <p className="h5">Requirement Gathering</p>
            <p  className="pharagraph">Our team gathers all the requirements from the client in this phase and we research deep into the market before deciding on the plans.
            </p>
            <br />
            <p className="h5">Planning </p>
            <p  className="pharagraph">The planning phase includes structuring a detailed outline of how the P2P Crypto exchange development is set to be executed. 
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <p className="h5">Designing</p>
            <p  className="pharagraph">Our well-experienced designers will design your p2p crypto exchange software with a breathtaking user interface that provides a smooth trading experience for your users.
            </p>
            <br />
            <p className="h5">Development</p>
            <p  className="pharagraph">In this phase, our development team will modify the features, visuals, and security modules of the P2P trading software according to the client's necessities.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/binance/design-development.png"
                alt="designing and development"
                width={259}
                height={289}
              />
            </div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center" >
          <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/binance/testing-and-deployment.png"
                alt="testing and deployment"
                width={348}
                height={259}
              />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <p className="h5">Testing</p>
            <p  className="pharagraph">After the development stage, we would check and verify our decentralized P2P software by conducting multiple tests which help us to deliver a bug-free product.
            </p>
            <br /> 
            <p className="h5">Deployment</p>
            <p  className="pharagraph mb-0">Once the product is tested by our experts, we will deploy the first-quality p2p crypto exchange into the server.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach