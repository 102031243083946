import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0">
        <div className="container">
                <h2 className="heading-h2 text-center">P2P Cryptocurrency Exchange <span className='bluecolor'>Development Services</span></h2>
            <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2" >
            <p className="pharagraph" >Coinsclone’s P2P Crypto exchange development is reliable, and trustworthy helping startups to launch an efficient peer-to-peer exchange with extraordinary potential. We offer a feature-rich P2P Crypto exchange software with top security and the best-in-class functionalities in the market. Hence, your users can trade directly without the intervention of a central authority and ensure quicker transactions.
              </p>
              <p className="pharagraph mb-0">Our P2P Crypto exchange solution is completely tested, multi-chain compatible, and is ready to be deployed. With the help of a Smart contract-based Escrow system, we connect the buyers and sellers directly and form a fantastic platform for peer-to-peer trading. Develop a budget-friendly and eye-catching P2P trading platform with the finest modules based on our result-driven blockchain experts.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/p2p-new/what-is-p2p-crypto-exchange.png"
                alt="P2P Crypto Exchange"
                width={350}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis