import React, { Component } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading addons bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add-on modules</span> of our</span>
            P2P Crypto Exchange Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                Atomic Swap
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Staking module
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Preferred Trading selection
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                Two-factor Authentication
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Atomic Swap
                      </h4>
                      <p className="pharagraph">By identifying trusted users' transactions, P2P swaps do away with the requirement for a central authority.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/atomic-swap.png"
                      alt="Atomic Swap"
                      width={400}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Staking module
                      </h4>
                      <p className="pharagraph">It allows users to hold cryptocurrencies and also improves control, security, and liquidity.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/wallet-integration.png"
                      alt="Staking module"
                      width={400}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Preferred Trading selection
                      </h4>
                      <p className="pharagraph">For a more secure and reliable trading experience, buyers can freely select their preferred vendors and vice versa.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/staking-module.png"
                      alt="Preferred Trading selection"
                      width={303}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Two-factor Authentication</h4>
                      <p className="pharagraph">It guarantees access to a user's account and prevents the invention of fraudulent activity in P2P crypto exchange platform.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/escrow-system.png"
                      alt="Two-factor Authentication"
                      width={350}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon