import React from 'react'



class WeOffer extends React.Component {


  render() {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2">Top P2P Crypto Exchange Clone Scripts <span className="bluecolor">We Offer</span></h2>
          <p className="sub-heading-bold">Replicating an existing P2P business model is one of the smartest ways to start a Crypto business. We offer some of the top trending P2P Crypto Exchange clone scripts to brighten your ROI. </p>
          <div className="factor">
            <div className="revenue">
              <a href='https://www.coinsclone.com/binance-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/binance-logo.png" alt="Binance" />
              <span>Binance Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/paxful-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/paxful.png" alt="Paxful" /><span>Paxful Clone Script</span></p></a>
            </div>
            <div className="revenue">
              <a href='https://www.coinsclone.com/remitano-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/remitano.png" alt="Remitano" /><span>Remitano Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/kucoin-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/kucoin.png" alt="Kucoin" /><span>Kucoin Clone Script</span></p></a>
            </div>
            <div className="revenue">
              <a href='https://www.coinsclone.com/bybit-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/bybit.png" alt="Bybit" /><span>Bybit Clone Script</span></p></a>
              <a href='https://www.coinsclone.com/okx-clone-script/' className='mb-md-4 d-block'><p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/okx.png" alt="OKX" /><span>OKX Clone Script</span></p></a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WeOffer