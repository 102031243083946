import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/p2p/Banner"
import Whatis from "../components/p2p/Whatis"
import WhyShould from "../components/p2p/WhyShould"
import TopFeatures from "../components/p2p/TopFeatures"
import HowOur from "../components/p2p/HowOur"
import CoreFeatures from "../components/p2p/CoreFeatures"
import SecurityofOur from "../components/p2p/SecurityofOur"
//import UsecaseOf from "../components/p2p/UsecaseOf"
import WhyChoose from "../components/p2p/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import PortfolioSection from "../components/whitelabelpage/Portfolio"
import DevApproach from "../components/p2p/DevApproach"
import TechStack from "../components/p2p/TechStack"
import FaqSection from "../components/p2p/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import Addon from "../components/p2p/Addon"
import BenifitsOf from "../components/p2p/BenifitsOf"
import ReviewNew from "../components/Landing/Review-new"
import WeOffer from "../components/p2p/WeOffer"





const p2p = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>P2P Cryptocurrency Exchange Development Company</title>
        <meta name="description" content="Get P2P Crypto Exchange development services from Coinsclone and transform your trading business. We offer Secure and feature-rich P2P Exchange software at an affordable cost." />
        <meta name="keywords" content="P2P Crypto Exchange Development Company, P2P Crypto Exchange Script, White Label P2P Crypto Exchange, P2P crypto exchange software, Peer-to-Peer Exchange Development, P2P Exchange Development Services, P2P decentralized exchange" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="P2P Cryptocurrency Exchange Development Company" />
        <meta property="og:description" content="Get P2P Crypto Exchange development services from Coinsclone and transform your trading business. We offer Secure and feature-rich P2P Exchange software at an affordable cost." />
        <meta property="og:url" content="https://www.coinsclone.com/p2p-crypto-exchange-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/p2p-new/p2p-crypto-exchange-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Get P2P Crypto Exchange development services from Coinsclone and transform your trading business. We offer Secure and feature-rich P2P Exchange software at an affordable cost." />
        <meta name="twitter:title" content="P2P Cryptocurrency Exchange Development Company" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/p2p-new/p2p-crypto-exchange-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/p2p-crypto-exchange-development/" />
      </Helmet>
      
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | P2P Crypto Exchange Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <HowOur />
      <DevApproach />
      <BenifitsOf />
      {/* <UsecaseOf /> */}
      <WeOffer />
      <WhyChoose />
      <Testimonial />
      <ReviewNew />
      <PortfolioSection />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default p2p
