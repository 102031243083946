import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
            <h1 className="main-heading text-center">P2P Crypto Exchange Development Company</h1>
            <p className="sub-heading mb-3 text-center">Transform your P2P Crypto Business to the next level with our high-grade P2P Crypto Exchange development services for a decentralized user experience.</p>
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/p2p-new/p2p-crypto-exchange-development.png"
            alt="P2P Crypto Exchange  Development Company"
            width={810}
          />
          <div className="cta-banner justify-content-md-center">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection