import React from 'react'



class BenifitsOf extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="secureof lucrative new-benif pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Benefits of</span> choosing our</span> P2P Crypto Exchange Development Services</h2>
          <p className="sub-heading-bold">The P2P Crypto exchange software we provide has significant benefits for entrepreneurs and upcoming startups in the global market.</p>
          <div className="factor wallet">
            <div className="revenue">
              <p >Institutional-grade security</p>
              <p >Quick time to market</p>
            </div>
            <div className="revenue">
              <p>Transparency</p>
              <p>Anonymity & Privacy</p>
            </div>
            <div className="revenue">
              <p>Scalability</p>
              <p>Customer Support</p>
            </div>
            <div className="revenue">
              <p >High Return on Investment </p>
              <p >Flawless Customization</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf